<template>
    <div>
        <v-layout row justify-center>
            <v-flex xs12>
                <div class="custom-section">
                    <div class="card-section">
                        <v-card color="rgba(255, 255, 255, 0.98)" style="border-radius:6px">
                            <v-card-text>
                                <img src="/static/img/logo.png" class="img-responsive mb-4" >
                                <h2 class="mb-4">iQuote</h2>
                                <p class="fs-14">Enter username and password to access.</p>
                                <v-layout row wrap no-gutters>
                                    <v-flex xs12>
                                      <v-text-field class="ma-2" label="Username" v-model="credentials.code" required></v-text-field>	
                                      <v-text-field class="ma-2" label="Password" v-model="credentials.password" type="password" :rules="passwordRules" required ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                      <v-checkbox color="primary" label="Remember me" v-model="is_checked_remember_me"></v-checkbox>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                      <v-btn  @click="logMeIn()" @keyup.enter="callEvent" block color="primary" class="mb-2" style="border-radius:60px">Login</v-btn>
                                      <!-- <v-btn  @click="logMeIn()" @keyup.enter="logMeIn()" block color="primary" class="mb-2" style="border-radius:60px">Login</v-btn> -->
                                    </v-flex>
                                    <!-- <v-flex xs12 sm12 md12 class="text-center">
                                        <router-link class="mb-2" to="/session/forgot-password">{{$t('message.forgotPassword')}}?</router-link>
                                    </v-flex> -->
                                </v-layout>
                            </v-card-text>
                        </v-card>
                    </div>
                </div> 
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import axios from 'axios'
import AppConfig from "@/constants/AppConfig"
import {api} from '@/components/apiurls'

export default {
  name: 'Login',
  data () {
    return {
      is_checked_remember_me:false,
      valid: false,
      appLogo: AppConfig.appLogo,
      reset_password_modal:false,
      passwordRules: [v => !!v || "Password is required"],
      credentials: {
        code: '',
        password: ''
      },
      //previous vue code
      message: 'Enter the email address linked to your account',
      messageModal_header: 'dark',
      title: 'Reset Password',
      email:'',
      errorMessage:'',
    }
  },
  computed:{},
  async mounted()
  {
    window.addEventListener('keyup', this.logMeInWithEnter)

    let email = document.cookie.match('(^|;)\\s*' + 'email' + '\\s*=\\s*([^;]+)')
    let password = document.cookie.match('(^|;)\\s*' + 'password' + '\\s*=\\s*([^;]+)')
      
    this.credentials.code = email ? email.pop() : ''
    this.credentials.password = password ? password.pop() : ''

  },
  methods: {
  
    logMeInWithEnter(event)
    {
      //console.log(this.$route)

      let route_name = this.$route.name
      //console.log(event.keyCode)
      if (event.keyCode === 13 && route_name == 'login') 
      {
        this.logMeIn()
      }
    },
    get_link_by_email()
    {
      alert('sent new password to your email.')
      /*axios.post(apiResetPassword, {email:this.email}, authHeader())
      .then (res => 
      { 
        var r = res.data
        if (r.code == 1)
        { 
          var data = r.data
          this.messageModalMsg = r.msg
          this.messageModal_header = 'dark'
          this.title = ''
          this.messageModal = true
        }
        else
        {
          this.messageModalMsg = r.msg
          this.messageModal_header = 'danger'
          this.title = 'Error'
        }
      })
      .catch(error => alert(error))*/
    },
    reset_password()
    {
      this.email = ''
      
      this.reset_password_modal = true
    },
    logMeIn() 
    {
      let email = this.credentials.code.trim()
      let password = this.credentials.password.trim()

      localStorage.clear()   
      if(this.is_checked_remember_me)
        {
          let d = new Date()
          d.setTime(d.getTime() + (180*24*60*60*1000))
          document.cookie = 'email=' + email + ';expires=' + d.toUTCString() + ':path=/'
          document.cookie = 'password=' + password + ';expires='+ d.toUTCString() + ':path=/'
        } 
        else
        {
          document.cookie = 'email=; expires= Thu, 01 Jan 1970 00:00:00: UTC; path=/;'
          document.cookie = 'password=; expires= Thu, 01 Jan 1970 00:00:00: UTC; path=/;'
        }

        axios.post(api['Login'], 
        {
          code: email,
          password: password
        })
        .then (res => {
          if (res.data.code == 1)
          {
            let user = this.credentials

            this.$store.dispatch("login", {
              user
            });
          }
          else 
          {
            this.errorMessage = res.data.msg
          }
        })
        .catch(error => alert("Error login: " + error))

        this.reset_search_count()
    },
    reset_search_count()
    {
      // store first search after login 
      let doc = {
          sq_count: 0,
          so_count: 0,
          po_count: 0
      }
      localStorage.setItem('search',JSON.stringify(doc));
    }
  }
}
</script>
<style>
 .custom-section {
    background-image:url('/static/img/shipping.jpg');
    background-size: auto auto;
    background-size: cover;
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    width:100%;
  }
  .card-section{
      align:'center';
      padding-left:10px;
      padding-right: 10px;
  }
  .centered-content{
    /* width:50%; */
    position: absolute;
    top: 50%;
    left: 50%;
    color:white;
    transform: translate(-50%, -50%);
  }
  .bottom-right-txt{
    position: absolute;
    color:white;
    bottom: 8px;
    right: 16px;
  }
  .box{
  width: 50%;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); 
  background-color:rgba(10,10,10,0.9);
  border-radius: 60px;
  box-shadow: 10px 12px 10px 6px rgba(10,10,50,0.9) ;
  text-align: center; 
}
</style>